<template>
  <div class="statistics-wrap">
    <a-tabs type="card" @change="changePage">
      <a-tab-pane key="organization" tab="组织数据" v-if="userInfo.leader">
        <div class="content-wrap">
          <div class="date-wrap">
            <div class="date-txt">我的组织数据：{{ statisticsData.deptName }}</div>
            <div>
              <a-radio-group button-style="solid" v-model="dateFlag" @change="selDateFlag">
                <a-radio-button value="month"> 本月 </a-radio-button>
                <a-radio-button value="week"> 本周 </a-radio-button>
                <a-radio-button value="day"> 本日 </a-radio-button>
              </a-radio-group>
              <a-range-picker
                style="margin-left: 12px"
                :format="dateFormat"
                @change="onChangeDate"
                v-model="classDateArr"
              />
              <!-- <a-select
                    v-model="orderStatus"
                    placeholder="订单状态"
                    allowClear
                    style="width:200px;margin-left:12px;"
                    @change="queryOrganizationData"
                    >
                  <a-select-option :value="i.value" v-for="i of orderStatusArr" :key="i.label">
                    {{ i.label }}
                  </a-select-option>
                  </a-select> -->
            </div>
          </div>
          <div class="card-wrap">
            <div class="card-item">
              <div class="card-header">
                <span class="big-label">续费</span>
              </div>
              <div class="card-content">
                <span class="card-label">全量</span>
                <span class="card-value" @click="hrefOrder('all')" v-if="statisticsData.renewAmount"
                  >$ {{ formatThousand(statisticsData.renewAmount) }}（{{ statisticsData.renewOrderNum }}）</span
                >
              </div>
              <div class="card-content">
                <span class="card-label">考核</span>
                <span class="card-value" @click="hrefOrder('renew')" v-if="statisticsData.renewAchievementAmount"
                  >$ {{ formatThousand(statisticsData.renewAchievementAmount) }}（{{
                    statisticsData.renewAchievementOrderNum
                  }}）</span
                >
              </div>

              <div class="loading-wrap" v-if="showLoadingTC">
                <a-spin />
              </div>
            </div>
            <div class="card-item">
              <div class="card-header">
                <span class="big-label">补升</span>
              </div>
              <div class="card-content">
                <span class="card-label">订单量/单</span>
                <span class="card-value" @click="hrefOrder('makeUp')">{{ statisticsData.makeUpOrderNum }}</span>
              </div>
              <div class="card-content">
                <span class="card-label">金额/美元</span>
                <span class="card-value" @click="hrefOrder('makeUp')"
                  >$ {{ formatThousand(statisticsData.makeUpAmount) }}</span
                >
              </div>

              <div class="loading-wrap" v-if="showLoadingTC">
                <a-spin />
              </div>
            </div>
            <div class="card-item">
              <div class="card-header">
                <span class="big-label">推荐</span>
              </div>
              <div class="card-content">
                <span class="card-label">转介绍线索</span>
                <span class="card-value" @click="hrefClue">{{ statisticsData.trackCount }}</span>
              </div>
              <div class="card-content">
                <span class="card-label">学员分享率</span>
                <span v-if="statisticsData.shareRate === '-'">-</span>
                <span class="card-value" v-else @click="hrefShareRate(startTime, endTime)">{{
                  statisticsData.shareRate
                }}</span>
              </div>

              <div class="loading-wrap" v-if="showLoadingTC">
                <a-spin />
              </div>
            </div>
          </div>
        </div>

        <div class="main-wrap">
          <a-tabs default-active-key="1" @change="changeTable">
            <a-tab-pane key="myOrganization" tab="我的组织">
              <div class="task-nav">
                <div style="flex: 1">
                  <a-row :gutter="24">
                    <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" class="ant-advanced-search-form">
                      <a-col :span="6">
                        <a-form-model-item label="组织(部门):">
                          <a-tree-select
                            v-model="deptId"
                            style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="treeData"
                            placeholder="请选择"
                            tree-default-expand-all
                          >
                          </a-tree-select>
                        </a-form-model-item>
                      </a-col>

                      <!-- <a-col :span="7">
                        <a-form-model-item label="订单状态">
                          <a-select
                            placeholder="请选择"
                            allowClear
                            @change="queryOrganizationTableData('search')"
                            v-model="queryOrderStatus"
                          >
                            <a-select-option :value="item.value" v-for="(item, index) in orderStatusArr" :key="index">
                              {{ item.label }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col> -->
                      <a-col :span="8">
                        <a-form-model-item label="时间范围">
                          <a-range-picker
                            @change="onChangeTableDate"
                            v-model="queryClassDateArr"
                            :format="dateFormat"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-form-model>
                  </a-row>
                </div>
                <div class="button-box">
                  <a-button type="primary" style="margin-right: 10px" @click="queryOrganizationTableData('search')"
                    >查询</a-button
                  >
                  <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
                </div>
              </div>
              <div class="table-wrap">
                <a-table
                  :loading="showLoading"
                  :columns="myColumns"
                  :data-source="myTableData"
                  @change="handleTableChange"
                  :pagination="false"
                  :rowKey="
                    (record, index) => {
                      return index;
                    }
                  "
                >
                  <template slot="renewAmount" slot-scope="renewAmount, record">
                    <span>{{ record.renewAmount }} ({{ record.renewOrderNum }})</span>
                  </template>
                  <template slot="renewAchievementAmount" slot-scope="renewAchievementAmount, record">
                    <span>{{ record.renewAchievementAmount }} ({{ record.renewAchievementOrderNum }})</span>
                  </template>
                  <template slot="makeUpAmount" slot-scope="makeUpAmount, record">
                    <span>{{ record.makeUpAmount }} ({{ record.makeUpOrderNum }})</span>
                  </template>
                  <template slot="shareRate" slot-scope="shareRate, record">
                    <span v-if="shareRate === '-'"> - </span>
                    <span
                      style="color: #04cb94; cursor: pointer"
                      v-else
                      @click="hrefShareRateSub(queryStartTime, queryEndTime, record.deptId)"
                      >{{ shareRate }}</span
                    >
                  </template>
                </a-table>
              </div>
            </a-tab-pane>
            <a-tab-pane key="peerOrganization" tab="同级组织">
              <div class="task-nav">
                <div style="flex: 1">
                  <a-row :gutter="24">
                    <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" class="ant-advanced-search-form">
                      <!-- <a-col :span="7">
                        <a-form-model-item label="订单状态">
                          <a-select
                            placeholder="请选择"
                            allowClear
                            @change="queryPeerTableData('search')"
                            v-model="queryPeerOrderStatus"
                          >
                            <a-select-option :value="item.value" v-for="(item, index) in orderStatusArr" :key="index">
                              {{ item.label }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col> -->
                      <a-col :span="7">
                        <a-form-model-item label="时间范围">
                          <a-range-picker
                            @change="onChangePeerTableDate"
                            v-model="queryPeerDateArr"
                            format="YYYY-MM-DD"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-form-model>
                  </a-row>
                </div>
                <div class="button-box">
                  <a-button type="primary" style="margin-right: 10px" @click="queryPeerTableData('search')"
                    >查询</a-button
                  >
                  <a-button style="margin-right: 10px" @click="resetPeerCondition">重置</a-button>
                </div>
              </div>
              <div class="table-wrap">
                <a-table
                  :loading="showPeerLoading"
                  :columns="columns"
                  :data-source="peerTableData"
                  @change="handlePeerTableChange"
                  :pagination="false"
                  :rowKey="
                    (record, index) => {
                      return index;
                    }
                  "
                >
                  <template slot="renewAmount" slot-scope="renewAmount, record">
                    <span>{{ record.renewAmount }} ({{ record.renewOrderNum }})</span>
                  </template>
                  <template slot="renewAchievementAmount" slot-scope="renewAchievementAmount, record">
                    <span>{{ record.renewAchievementAmount }} ({{ record.renewAchievementOrderNum }})</span>
                  </template>
                  <template slot="makeUpAmount" slot-scope="makeUpAmount, record">
                    <span>{{ record.makeUpAmount }} ({{ record.makeUpOrderNum }})</span>
                  </template>
                  <template slot="shareRate" slot-scope="shareRate">
                    <span>{{ shareRate }}</span>
                  </template>
                </a-table>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </a-tab-pane>
      <a-tab-pane key="member" tab="成员数据">
        <div class="task-nav">
          <div style="flex: 1">
            <a-row :gutter="24">
              <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" class="ant-advanced-search-form">
                <a-col :span="6">
                  <a-form-model-item label="组织(部门):">
                    <a-tree-select
                      v-model="searchDeptId"
                      style="width: 100%"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      :tree-data="treeData"
                      placeholder="请选择"
                      tree-default-expand-all
                    >
                    </a-tree-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item label="员工姓名:">
                    <a-input
                      placeholder="请输入"
                      v-model="searchName"
                      allow-clear
                      @pressEnter="initSearchData('search')"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- <a-col :span="5">
                             <a-form-model-item label="员工邮箱:">
                               <a-input
                                placeholder="请输入"
                                v-model="searchEmail"
                                allow-clear
                                @pressEnter="initSearchData('search')"
                              />
                           
                        </a-form-model-item>
                      </a-col> -->
                <!-- <a-col :span="5">
                        <a-form-model-item label="订单状态">
                          <a-select
                            placeholder="请选择"
                            allowClear
                            @change="initSearchData('search')"
                            v-model="searchOrderStatus"
                          >
                            <a-select-option :value="item.value" v-for="(item, index) in orderStatusArr" :key="index">
                              {{ item.label }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col> -->
                <a-col :span="8">
                  <a-form-model-item label="时间范围">
                    <a-range-picker @change="onChangeMemberDate" v-model="searchDateArr" format="YYYY-MM-DD" />
                  </a-form-model-item>
                </a-col>
              </a-form-model>
            </a-row>
          </div>
          <div class="button-box">
            <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
            <a-button style="margin-right: 10px" @click="resetMemberCondition">重置</a-button>
          </div>
        </div>
        <div class="table-wrap">
          <a-table
            :loading="showMemberLoading"
            :columns="memberColumns"
            :data-source="tableMemberData"
            @change="handleMemberChange"
            :pagination="initPagination"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template slot="renewAmount" slot-scope="renewAmount, record">
              <span>{{ record.renewAmount }} ({{ record.renewOrderNum }})</span>
            </template>
            <template slot="renewAchievementAmount" slot-scope="renewAchievementAmount, record">
              <span>{{ record.renewAchievementAmount }} ({{ record.renewAchievementOrderNum }})</span>
            </template>
            <template slot="makeUpAmount" slot-scope="makeUpAmount, record">
              <span>{{ record.makeUpAmount }} ({{ record.makeUpOrderNum }})</span>
            </template>
            <template slot="shareRate" slot-scope="shareRate, record">
              <span v-if="shareRate === '-'"> - </span>
              <span
                style="color: #04cb94; cursor: pointer"
                v-else
                @click="hrefShareRate(searchStartTime, searchEndTime, record.accountId)"
                >{{ shareRate }}</span
              >
            </template>
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import storage from 'store';
import moment from 'moment';
import {
  OrganizationData,
  OrganizationTableData,
  getTreeList,
  peerTableData,
  memberTableData,
} from '@/api/headTeacher';

export default {
  name: 'statisticsIndex',
  data() {
    return {
      showLoadingTC: false,
      userInfo: {},
      tableMemberData: [],
      searchDeptId: undefined,
      memberSorter: {},
      showMemberLoading: false,
      searchDateArr: [],
      searchStartTime: '',
      searchEndTime: '',
      searchOrderStatus: 'PAYMENT_RECEIVED',
      searchEmail: undefined,
      searchName: undefined,
      tablePeerSorter: {},
      queryPeerDateArr: [],
      queryPeerOrderStatus: 'PAYMENT_RECEIVED',
      showPeerLoading: false,
      deptId: undefined,
      treeData: [],
      showLoading: false,
      myTableData: [],
      peerTableData: [],
      queryClassDateArr: [],
      queryStartTime: '',
      queryEndTime: '',
      queryOrderStatus: 'PAYMENT_RECEIVED',
      statisticsData: {},
      startTime: '',
      endTime: '',
      dateFlag: 'month',
      dateFormat: 'YYYY-MM-DD',
      originationArr: [],
      origination: '',
      classDateArr: [],
      orderStatus: undefined,
      memberColumns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '姓名',
          dataIndex: 'accountName',
          key: 'accountName',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: '续费全量金额/美元(订单量)',
          dataIndex: 'renewAmount',
          key: 'renewAmount',

          scopedSlots: { customRender: 'renewAmount' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  // 用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
                  if (this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email !== record.email) {
                    this.$message.warning('暂无数据查看权限');
                    return false;
                  }
                  if (record.renewOrderNum) {
                    this.$router.push({
                      path: '/orderIndex',
                      query: { orderIds: JSON.stringify(record.renewOrderIds) },
                    });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '续费业绩金额/美元(订单量)',
          dataIndex: 'renewAchievementAmount',
          key: 'renewAchievementAmount',

          scopedSlots: { customRender: 'renewAchievementAmount' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  // 用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
                  if (this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email !== record.email) {
                    this.$message.warning('暂无数据查看权限');
                    return false;
                  }
                  if (record.renewAchievementOrderNum) {
                    this.$router.push({
                      path: '/orderIndex',
                      query: { orderIds: JSON.stringify(record.renewAchievementOrderIds) },
                    });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '补升金额/美元(订单量)',
          dataIndex: 'makeUpAmount',
          key: 'makeUpAmount',
          scopedSlots: { customRender: 'makeUpAmount' },

          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  // 用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
                  if (this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email !== record.email) {
                    this.$message.warning('暂无数据查看权限');
                    return false;
                  }
                  if (record.makeUpOrderNum) {
                    this.$router.push({
                      path: '/orderIndex',
                      query: { orderIds: JSON.stringify(record.makeUpOrderIds) },
                    });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '转介绍线索',
          dataIndex: 'trackCount',
          key: 'trackCount',

          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  // 用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
                  if (this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email !== record.email) {
                    this.$message.warning('暂无数据查看权限');
                    return false;
                  }
                  if (record.trackCount) {
                    this.$router.push({ path: '/referralIndex', query: { trackIds: JSON.stringify(record.trackIds) } });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '学员分享率',
          dataIndex: 'shareRate',
          key: 'shareRate',

          // scopedSlots: { customRender: 'shareRate' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  // 用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
                  if (this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email !== record.email) {
                    this.$message.warning('暂无数据查看权限');
                    return false;
                  }
                  if (record.shareRate && record.shareRate !== '-' && record.shareRate !== '0%') {
                    this.hrefShareRate(this.searchStartTime, this.searchEndTime, record.accountId, record.subject);
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        // {
        //   title: '续费订单量',
        //   dataIndex: 'renewOrderNum',
        //   key: 'renewOrderNum',
        //   customCell: (record) => {
        //     return {
        //       style: {
        //         // 行背景色
        //         color: '#04CB94',
        //         cursor: 'pointer',
        //       },
        //       on: {
        //         // 鼠标单击行
        //         click: () => {
        //           //用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
        //           if(this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email!==record.email){
        //               this.$message.warning("暂无数据查看权限")
        //               return false
        //           }
        //           if(record.renewOrderNum){
        //               this.$router.push({path:'/orderIndex',query:{orderIds:JSON.stringify(record.renewOrderIds)}})
        //           }else{
        //             this.$message.warning('暂无数据')
        //           }

        //         },
        //       },
        //     };
        //   },
        // },
        // {
        //   title: '续费金额/元',
        //   dataIndex: 'renewAmount',
        //   key: 'renewAmount',
        // },
        // {
        //   title: '补升订单量',
        //   dataIndex: 'makeUpOrderNum',
        //   key: 'makeUpOrderNum',
        //  customCell: (record) => {
        //     return {
        //       style: {
        //         // 行背景色
        //         color: '#04CB94',
        //         cursor: 'pointer',
        //       },
        //       on: {
        //         // 鼠标单击行
        //         click: () => {
        //           //用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
        //           if(this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email!==record.email){
        //               this.$message.warning("暂无数据查看权限")
        //               return false
        //           }
        //           if(record.makeUpOrderNum){
        //               this.$router.push({path:'/orderIndex',query:{orderIds:JSON.stringify(record.makeUpOrderIds)}})

        //           }else{
        //             this.$message.warning('暂无数据')
        //           }

        //         },
        //       },
        //     };
        //   },
        // },
        // {
        //   title: '补升金额/元',
        //   dataIndex: 'makeUpAmount',
        //   key: 'makeUpAmount',
        // },
        // {
        //   title: '转介绍线索',
        //   dataIndex: 'trackCount',
        //   key: 'trackCount',
        //   customCell: (record) => {
        //     return {
        //       style: {
        //         // 行背景色
        //         color: '#04CB94',
        //         cursor: 'pointer',
        //       },
        //       on: {
        //         // 鼠标单击行
        //         click: () => {
        //           //用户角色==班主任，那么当前登陆用户只能查看列表中邮箱==登陆用户邮箱的数据明
        //           if(this.userInfo.roleName === 'CLASS_ADMIN' && this.userInfo.email!==record.email){
        //               this.$message.warning("暂无数据查看权限")
        //               return false
        //           }
        //           if(record.trackCount){
        //             this.$router.push({path:'/referralIndex',query:{trackIds:JSON.stringify(record.trackIds)}})
        //           }else{
        //             this.$message.warning('暂无数据')
        //           }

        //         },
        //       },
        //     };
        //   },
        // },
      ],
      orderStatusArr: [
        { label: '待支付', value: 'PENDING_PAYMENT' },
        { label: '已支付待确认', value: 'CONFIRMED' },
        { label: '已支付', value: 'PAYMENT_RECEIVED' },
        { label: '已取消', value: 'CANCELLED' },
      ],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '组织',
          dataIndex: 'deptName',
          key: 'deptName',
        },
        {
          title: '组织负责人',
          dataIndex: 'accountName',
          key: 'accountName',
        },
        {
          title: '续费全量金额/美元(订单量)',
          dataIndex: 'renewAmount',
          key: 'renewAmount',

          scopedSlots: { customRender: 'renewAmount' },
        },
        {
          title: '续费业绩金额/美元(订单量)',
          dataIndex: 'renewAchievementAmount',
          key: 'renewAchievementAmount',

          scopedSlots: { customRender: 'renewAchievementAmount' },
        },
        {
          title: '补升金额/美元(订单量)',
          dataIndex: 'makeUpAmount',
          key: 'makeUpAmount',
          scopedSlots: { customRender: 'makeUpAmount' },
        },
        {
          title: '转介绍线索',
          dataIndex: 'trackCount',
          key: 'trackCount',
        },
        {
          title: '学员分享率',
          dataIndex: 'shareRate',
          key: 'shareRate',

          scopedSlots: { customRender: 'shareRate' },

          //  customCell: (record) => {
          //     return {
          //       style: {
          //         // 行背景色
          //         color: '#04CB94',
          //         cursor: 'pointer',
          //       },
          //       on: {
          //         // 鼠标单击行
          //         click: () => {
          //            this.hrefShareRate(this.queryPeerStartTime,this.queryPeerEndTime)
          //         },
          //       },
          //     };
          //   },
        },
      ],
      myColumns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '组织',
          dataIndex: 'deptName',
          key: 'deptName',
        },
        {
          title: '组织负责人',
          dataIndex: 'accountName',
          key: 'accountName',
        },
        {
          title: '续费全量金额/美元(订单量)',
          dataIndex: 'renewAmount',
          key: 'renewAmount',

          scopedSlots: { customRender: 'renewAmount' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.renewOrderNum) {
                    this.$router.push({
                      path: '/orderIndex',
                      query: { orderIds: JSON.stringify(record.renewOrderIds) },
                    });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '续费业绩金额/美元(订单量)',
          dataIndex: 'renewAchievementAmount',
          key: 'renewAchievementAmount',

          scopedSlots: { customRender: 'renewAchievementAmount' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.renewAchievementOrderNum) {
                    this.$router.push({
                      path: '/orderIndex',
                      query: { orderIds: JSON.stringify(record.renewAchievementOrderIds) },
                    });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '补升金额/美元(订单量)',
          dataIndex: 'makeUpAmount',
          key: 'makeUpAmount',
          scopedSlots: { customRender: 'makeUpAmount' },

          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.makeUpOrderNum) {
                    this.$router.push({
                      path: '/orderIndex',
                      query: { orderIds: JSON.stringify(record.makeUpOrderIds) },
                    });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '转介绍线索',
          dataIndex: 'trackCount',
          key: 'trackCount',

          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#04CB94',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  if (record.trackCount) {
                    this.$router.push({ path: '/referralIndex', query: { trackIds: JSON.stringify(record.trackIds) } });
                  } else {
                    this.$message.warning('暂无数据');
                  }
                },
              },
            };
          },
        },
        {
          title: '学员分享率',
          dataIndex: 'shareRate',
          key: 'shareRate',

          scopedSlots: { customRender: 'shareRate' },

          //  customCell: (record) => {
          //     return {
          //       style: {
          //         // 行背景色
          //         color: '#04CB94',
          //         cursor: 'pointer',
          //       },
          //       on: {
          //         // 鼠标单击行
          //         click: () => {
          //            this.hrefShareRate(this.queryStartTime,this.queryEndTime)
          //         },
          //       },
          //     };
          //   },
        },
      ],
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      current: 0,
      pageSize: 10,
      tableSorter: {},
    };
  },
  methods: {
    moment,
    hrefShareRateSub(startTime, endTime, deptId) {
      let params = {
        startTime: startTime,
        endTime: endTime,
        deptId: deptId,
      };

      this.$router.push({
        path: '/studentShareDetail',
        query: params,
      });
    },
    hrefShareRate(startTime, endTime, accountId, subject) {
      let params = {
        startTime: startTime,
        endTime: endTime,
        subject: subject,
      };
      if (accountId) {
        params.accountId = accountId;
      }
      this.$router.push({
        path: '/studentShareDetail',
        query: params,
      });
    },
    hrefOrder(type) {
      // 续费考核
      if (type === 'renew') {
        if (this.statisticsData.renewAchievementOrderNum) {
          this.$router.push({
            path: '/orderIndex',
            query: { orderIds: JSON.stringify(this.statisticsData.renewAchievementOrderIds) },
          });
        } else {
          this.$message.warning('暂无数据');
        }
      } else if (type === 'all') {
        // 全量
        if (this.statisticsData.renewOrderNum) {
          this.$router.push({
            path: '/orderIndex',
            query: { orderIds: JSON.stringify(this.statisticsData.renewOrderIds) },
          });
        } else {
          this.$message.warning('暂无数据');
        }
      } else if (type === 'makeUp') {
        // 补升
        if (this.statisticsData.makeUpOrderNum) {
          this.$router.push({
            path: '/orderIndex',
            query: { orderIds: JSON.stringify(this.statisticsData.makeUpOrderIds) },
          });
        } else {
          this.$message.warning('暂无数据');
        }
      }
    },
    hrefClue() {
      if (this.statisticsData.trackCount) {
        this.$router.push({
          path: '/referralIndex',
          query: { trackIds: JSON.stringify(this.statisticsData.trackIds) },
        });
      } else {
        this.$message.warning('暂无数据');
      }
    },
    formatThousand(num) {
      if (num != null) {
        const res = num.toString().replace(/\d+/, function (n) {
          // 先提取整数部分
          return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
            return `${$1},`;
          });
        });
        return res;
      }
      return num;
    },
    handleMemberChange(data, filters, sorter) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.initSearchData();
    },

    onChangeMemberDate(date) {
      if (date.length > 0) {
        this.searchStartTime = date[0].format('YYYY-MM-DD');
        this.searchEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.searchStartTime = null;
        this.searchEndTime = null;
      }
      this.initSearchData('search');
    },
    resetMemberCondition() {
      this.searchDateArr = [];
      this.searchStartTime = '';
      this.searchEndTime = '';
      this.searchOrderStatus = undefined;
      this.searchEmail = undefined;
      this.searchName = undefined;
      this.searchDeptId = undefined;
    },
    resetCondition() {
      this.deptId = undefined;
      this.queryStartTime = '';
      this.queryEndTime = '';
      this.queryClassDateArr = [];
      this.queryOrderStatus = undefined;
    },
    resetPeerCondition() {
      this.queryPeerStartTime = '';
      this.queryPeerEndTime = '';
      this.queryPeerDateArr = [];
      this.queryPeerOrderStatus = undefined;
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
        // 成员数据回显组织部门
        this.searchDeptId = this.userInfo.leaderDeptId;
      });
    },
    handlePeerTableChange(data, filters, sorter) {
      this.tablePeerSorter = sorter;

      this.queryPeerTableData('search');
    },
    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;

      this.queryOrganizationTableData('search');
    },
    selDateFlag(e) {
      this.classDateArr = [];
      // 获取本月，本周，本日的开始时间跟结束时间
      const flag = e.target.value;
      if (flag === 'month') {
        this.startTime = this.$moment().month(this.$moment().month()).startOf('month').format('YYYY-MM-DD');
        this.endTime = this.$moment().month(this.$moment().month()).endOf('month').format('YYYY-MM-DD');
      } else if (flag === 'week') {
        this.startTime = this.$moment().week(this.$moment().week()).startOf('week').format('YYYY-MM-DD');
        this.endTime = this.$moment().week(this.$moment().week()).endOf('week').format('YYYY-MM-DD');
      } else if (flag === 'day') {
        this.startTime = this.$moment().format('YYYY-MM-DD');
        this.endTime = this.$moment().format('YYYY-MM-DD');
      }
      this.queryOrganizationData();
    },
    changePage(key) {
      if (key === 'member' && this.userInfo.leaderDeptId) {
        this.searchDeptId = this.userInfo.leaderDeptId;
        this.initSearchData('search');
      }
    },
    changeTable(val) {
      if (val === 'peerOrganization') {
        this.queryPeerTableData('search');
      }
    },
    initSearchData(act) {
      console.log(this.pageSize);
      if (!this.searchDeptId) {
        this.$message.warning('请先选择组织(部门)');
        return false;
      }
      if (this.searchDateArr.length === 0) {
        this.$message.warning('请选择时间范围');
        return false;
      }
      this.showMemberLoading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        email: this.searchEmail,
        name: this.searchName,
        deptId: this.searchDeptId,
        startTime: this.searchStartTime,
        endTime: this.searchEndTime,
        //  orderStatus:this.searchOrderStatus,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      let sort = '';
      if (this.memberSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.memberSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.memberSorter.order) {
        params.orderBy = this.memberSorter.columnKey;
        params.sort = sort;
      }

      memberTableData(params)
        .then((res) => {
          console.log(res);
          const { data } = res;
          this.tableMemberData = data.content;
          this.current = data.currentPage;
          this.pageSize = data.pageSize;
          this.initPagination.total = data.totalCount;
          this.initPagination.current = data.currentPage;
          this.showMemberLoading = false;
        })
        .catch((error) => {
          this.showMemberLoading = false;
        });
    },
    onChangeDate(date) {
      this.dateFlag = '';
      if (date.length > 0) {
        this.startTime = date[0].format('YYYY-MM-DD');
        this.endTime = date[1].format('YYYY-MM-DD');
      } else {
        this.startTime = null;
        this.endTime = null;
      }
      this.queryOrganizationData();
    },
    onChangePeerTableDate(date) {
      if (date.length > 0) {
        this.queryPeerStartTime = date[0].format('YYYY-MM-DD');
        this.queryPeerEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryPeerStartTime = null;
        this.queryPeerEndTime = null;
      }
    },
    onChangeTableDate(date) {
      if (date.length > 0) {
        this.queryStartTime = date[0].format('YYYY-MM-DD');
        this.queryEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryStartTime = null;
        this.queryEndTime = null;
      }
    },
    queryPeerTableData(act) {
      if (this.queryPeerDateArr.length === 0) {
        this.$message.warning('请选择时间范围');
        return false;
      }

      this.showPeerLoading = true;
      const params = {
        startTime: this.queryPeerStartTime,
        endTime: this.queryPeerEndTime,
        // orderStatus:this.queryPeerOrderStatus
      };
      let sort = '';
      if (this.tablePeerSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tablePeerSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tablePeerSorter.order) {
        params.orderBy = this.tablePeerSorter.columnKey;
        params.sort = sort;
      }
      peerTableData(params)
        .then((res) => {
          this.peerTableData = res.data.content;
          this.showPeerLoading = false;
        })
        .catch((error) => {
          this.showPeerLoading = false;
        });
    },
    queryOrganizationData() {
      this.showLoadingTC = true;
      const params = {
        startTime: this.startTime,
        endTime: this.endTime,
        // orderStatus:this.orderStatus
      };
      OrganizationData(params)
        .then((res) => {
          this.statisticsData = res.data.content;
          this.showLoadingTC = false;
        })
        .catch((error) => {
          this.showLoadingTC = false;
        });
    },
    queryOrganizationTableData(act) {
      if (!this.deptId) {
        this.$message.warning('请先选择组织(部门)');
        return false;
      }
      if (this.queryClassDateArr.length === 0) {
        this.$message.warning('请选择时间范围');
        return false;
      }

      this.showLoading = true;
      const params = {
        deptId: this.deptId,
        startTime: this.queryStartTime,
        endTime: this.queryEndTime,
        // orderStatus:this.queryOrderStatus
      };
      let sort = '';
      if (this.tableSorter.order === 'descend') {
        sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        sort = 'asc';
      }
      if (this.tableSorter.order) {
        params.orderBy = this.tableSorter.columnKey;
        params.sort = sort;
      }
      OrganizationTableData(params)
        .then((res) => {
          this.myTableData = res.data.content;
          this.showLoading = false;
        })
        .catch((error) => {
          this.showLoading = false;
        });
    },
  },
  created() {
    this.userInfo = storage.get('userInfo');
    // 获取组织部门,只有管理员才可以看组织数据页面

    this.getTreeList();
  },
  mounted() {
    // 默认查询本月的数据
    this.startTime = this.$moment().month(this.$moment().month()).startOf('month').format('YYYY-MM-DD');
    this.endTime = this.$moment().month(this.$moment().month()).endOf('month').format('YYYY-MM-DD');
    this.queryEndTime = this.endTime;
    this.queryStartTime = this.startTime;
    this.queryPeerStartTime = this.startTime;
    this.queryPeerEndTime = this.endTime;
    this.searchStartTime = this.startTime;
    this.searchEndTime = this.endTime;
    this.queryClassDateArr = [this.queryStartTime, this.queryEndTime];
    this.queryPeerDateArr = [this.queryPeerStartTime, this.queryPeerEndTime];
    this.searchDateArr = [this.searchStartTime, this.searchEndTime];
    if (this.userInfo.leader) {
      this.queryOrganizationData();
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-tabs-bar {
  padding: 12px 16px 0;
  background-color: #fff;
  margin: 0;
}
.statistics-wrap {
  width: 100%;
  min-height: calc(100vh - 64px);
}
.date-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.date-txt {
}
.card-wrap {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  position: relative;
}
.card-item {
  width: 30%;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 5px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  &:hover {
    background: #f3fefc;
  }
}
.card-header {
  justify-content: space-between;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}
.big-label {
  font-size: 16px;
  font-weight: bold;
}
.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.card-label {
  font-size: 16px;
  color: rgba(31, 45, 61, 0.6);
}
.card-value {
  font-size: 20px;
  color: #04cb94;
  cursor: pointer;
}
.card-value1 {
  font-size: 20px;
}
.main-wrap {
}
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.loading-wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding-top: 65px;
}
.loading-wrap1 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding-top: 20px;
}
.table-wrap {
  padding: 20px;
  background: #fff;
  min-height: calc(100vh - 200px);
}
.content-wrap {
  background-color: #fff;
  padding: 12px;
}
</style>
